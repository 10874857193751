/* ##remfixer: 1080 */

.article {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 0.296296rem 0.611111rem;
  margin-bottom: 0.666667rem;
}

.atc_top {
  width: 100%;
  overflow: hidden;
}

.atc_title {
  width: 100%;
  font-size: 0.555556rem;
  color: #000000;
  letter-spacing: 0;
  line-height: 0.722222rem;
  font-weight: 500;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 0.462963rem;
}

.atc_info {
  width: 100%;
  font-size: 0.333333rem;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 0;
  line-height: 0.388889rem;
  margin-bottom: 0.37037rem;
}

.atc_app {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  width: 9.111111rem;
  padding: 0.333333rem 0.444444rem;
  left: 50%;
  margin-left: -4.555556rem;
  margin-bottom: 0.444444rem;
  background: #f5f5f5;
  border-radius: 0.055556rem;
}

.atc_app .AppBlockNormal {
  padding: 0;
}

.atc_app .AppBlockNormal .img {
  width: 1.277778rem;
  height: 1.277778rem;
}

.atc_app .AppBlockNormal .text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 4.722222rem;
  height: 1.277778rem;
  margin: 0 0 0 0.444444rem;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.atc_app .AppBlockNormal .app-num {
  margin-bottom: 0;
}

.atc_content {
  width: 100%;
  font-size: 0.444444rem;
  color: #333333;
  letter-spacing: 0.018519rem;
}

.atc_content p {
  width: 100%;
  margin-bottom: 0.5rem;
}

.atc_content img {
  display: block;
  max-width: 100%;
  height: auto !important;
  margin: 0 auto;
}